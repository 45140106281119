export const index = `경기지역경제포털 개인정보 수집 및 이용

  제1장 [총칙]
경기지역경제포털(이하 “플랫폼”)에서 취급하는 모든 개인정보는 관련 법령에 의거하여 처리되며, 플랫폼 업무의 적절한 수행과 이용자의 권익 보호를 위하여 노력할 것입니다.
플랫폼은 개인정보보호 법령에 따라 이용자의 권익을 보호하고 개인정보와 관련한 이용자의 고충처리를 위하여 다음과 같은 개인정보 처리방침을 두고 있습니다.

제2장 [개인정보 수집 및 이용]
제1조 [목적]
경기지역경제플랫폼은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 목적 이외의 용도로는 이용되지 않으며, 목적이 변경되는 경우에는 이용자로부터 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.

① 이용목적 

1. 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인식별ㆍ인증, 회원자격 유지ㆍ관리, 제한적  본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지ㆍ통지, 고충처리 등의 목적

2. 민원인의 신원 확인, 민원사항 확인, 사실 조사를 위한 연락ㆍ통지, 처리결과 통보 등의 목적

3. 외부 위탁사무평가 및 설문 조사
○ 회원 만족도 조사 및 정책 수립에 필요한 통계 자료 작성, 설문조사 결과 분석 등
○ 위탁사무 수행 기관에 회원 정보(성명, 휴대전화번호, 이메일)를 제공

② 시스템 목록

1. 경기지역경제포털 시스템

제2조 [개인정보 이용 및 보유기간]
플랫폼은 개인정보의 처리 목적이 달성될 때까지 개인정보를 보유합니다. 단, 이용자로부터 사전에 동의를 받은 경우 또는 상법 등 관련 법령에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다.

1. 회원정보의 보유기간: 회원 탈퇴시
  다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
  - 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사․조사 종료시까지.
2. 계약 또는 청약철회 등에 관한 기록: 5년
3. 대금 결제 및 재화 등의 공급에 관한 기록: 5년
4. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
5. 기타 고객의 동의를 받은 경우 동의를 받은 기간

제3조 [개인정보 수집항목 및 수집 방법] 

① 플랫폼이 수집 및 처리하는 개인정보 항목은 다음과 같습니다.


1. 회원가입 및 관리
○ 성명, 아이디, 비밀번호, 휴대전화번호, 이메일주소, 소속기관/부서명, 직장 전화번호
2. 서비스 계약 및 거래
○ 성명, 아이디, 휴대전화번호, 이메일주소, 신용카드번호, 은행계좌정보 등 결제 정보
3. 영업 및 마케팅
○ 성명, 아이디, 휴대전화번호, 이메일주소
4. 민원 처리
○ 성명, 아이디, 이메일주소, 휴대전화번호
5. 외부 위탁사무평가 및 설문조사
○ 성명, 휴대전화번호, 이메일주소

② “플랫폼”은 아래의 방법을 통해 개인정보를 수집합니다.
1. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.
2. 민원처리 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인 정보가 수집될 수 있습니다.
3. 오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다.
4. 플랫폼과 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 관련법령에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 플랫폼에 제공합니다.
5. 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.

제4조 [동의 거부 권리 안내]

① 정보주체는 개인정보 수집·이용 동의를 거부할 수 있습니다. 
다만, 이 경우 경기지역경제 포털시스템을 사용하는데 제한이 있을 수 있습니다.

② 개인정보를 제공받는 자는 다음과 같습니다.
privacyCollectionAndAgreePolicyTable

③ 경기도 외부위탁사무평가기관 회원 만족도 조사 이름, 휴대전화번호, 이메일주소 설문 조사 완료 후 파기

제5조 [개인정보의 파기]
플랫폼은 개인정보의 처리목적이 달성되면 아래의 절차와 방법에 따라 개인정보를 파기합니다.

① 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 보유/이용기간이 종료한 경우 등의 사유발생시 당해 개인정보를 지체없이 파기합니다.

② 개인정보는 개인정보의 처리 목적이 달성되었거나, 해당 서비스의 폐지, 사업 종료 등으로 보유 개인정보가 불필요하게 되었을 경우, 별도의 DB로 옮겨져(서류의 경우 별도의 서류함) 개인정보의 처리 및 보유 기간에 따라 일정 기간 보관된 후 파기됩니다. 불필요한 것으로 인정되는 날로부터 5영업일 이내에 그 개인정보를 파기하며, 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다.

③ 다음 각 조의 사항이나 관련 법령에 따라 5영업일 이상 보존될 수 있습니다.
1. 상법 제33조 등 법령에 따라 보존하여야 하는 경우
2. 기타 이와 유사한 정당한 사유가 있는 경우


제6조 [개인정보 처리방침 변경]
① 본 개인정보 처리방침을 개정하는 경우에는 홈페이지를 통하여 공지할 것입니다. 개정된 개인정보 처리방침은 공지한 날로부터 7일 후부터 효력이 발생합니다.


부칙
(시행일) 본 방침은 2021년 11월 17일부터 시행합니다.

(시행일) 본 방침은 2020년 1월 21일부터 시행합니다.
`;