import React, {useState} from 'react';
import SelectBox from './SelectBox';

const Search = ({search, filter, onSearch}) => {
    let [selectFilter, setSelectFilter] = useState(filter[0].name);
    let [inputText, setInputText] = useState("");

    const filterChange = (item) => {
        setSelectFilter(item.target.value)
    }

    if(search){
        return (
            <>
            <div>
                <div className="search-wrap">
                    <div className="select-wrap">
                      {filter[0].name ==="" || <SelectBox filter={filter} onChange={filterChange}/>}
                    </div>
                    <div className="search-input">
                        <input className="imc-search-input" type="text" value={inputText} onChange={e => setInputText(e.target.value)}/>
                        <span>
                        <button className="imc-btn search btnclick" onClick={e => onSearch(selectFilter, inputText)}></button>
                        </span>
                    </div>
                </div>
            </div>
            <br/>
            </>
        )
    }
    else {
        return null;
    }
};

Search.defaultProps = {
    search: false,
    filter: [
        {
            name: "",
            display: ""
        }
    ],
    onSearch: () => {}
};

export default Search;
