import React from 'react';
import SelectBox from './SelectBox';

const Limit = ({limit, filter, onSelectLimit}) => {

    if(limit) {
        return (
            <div className="select-wrap">
                <div className="datatable-limit">
                    <SelectBox filter={filter} onChange={e => onSelectLimit(e.target.value)}/>
                </div>
            </div>   
        )
    } else{
        return null;
    }
};

Limit.defaultProps = {
    limit: false,
    filter: [
        {
            name: 10,
            display: 10
        },
        {
            name: 50,
            display: 50
        },
        {
            name: 100,
            display: 100
        }
    ]
};

export default Limit;
