import React from 'react';

const SelectBox = ({filter, onChange}) => {

    return (
        <select onChange={e => onChange(e)} className="imc-select">
        {filter.map((item, i) => 
            <option value={item.name} key={i}>{item.display}</option>
        )}
        </select>
    )
}

SelectBox.defaultProps = {
    filter: [
        {
            name: "",
            display: ""
        }
    ],
};

export default SelectBox;