import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as DataApi from "../../api/Data";
import * as OrderApi from "../../api/Order"
import * as FavoriteDataApi from "../../api/FavoriteData"
import {Link} from "react-router-dom";
import swal from "sweetalert";
import MetaInfoView from "../../components/MetaInfoView";
import DatasetResource from "../../components/DatasetResource";
import {yyyymmdd} from "../../util/Date";
import Indicator from "../../components/Indicator";
import {addComma} from "../../util/Number";
import {delCookie} from "../../util/Cookie";
import {terms} from "../../util/Terms";
import CheckBox from "../../components/CheckBox";
import ErrorPage404 from "../common/ErrorPage404";
import SelectBox from "../../components/SelectBox";
import ShareSNS from "../../components/SNS/ShareSNS";
import shareImg from "../../assets/images/ico_share.png";
import heartImg from "../../assets/images/ico_heart.png";
import sirenImg from "../../assets/images/ico_siren.png";
import DatasetComment from "../../components/DatasetComment";
import TourMessage from "../common/tourMessage";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {setTourBtn} from "../../store/actions/UserAction";
import Tour from "reactour";
import {index as contractPolicy} from "../../util/Terms/contractPolicy";
import SubNavNew from "../../components/SubNavNew";
import DatasetMetaView from "../../components/DatasetMetaView";
import swal2 from "sweetalert2";
import {TabItem} from "../../components/Tab";
import {delUserInfo, logout, setUserInfo} from "../../store/actions/UserAction";
import * as UserApi from "../../api/User";

let paramsId = null;
const Item = ({match, ...props}) => {
    const [dataInfo, setDataInfo] = useState(null);
    const [downloadCount, setDownloadCount] = useState(0);
    const [show404, setShow404] = useState(false);

    const subNav = [
        {
            display: '데이터셋',
            name: '/new-dataset'
        },
        {
            display: '융합 데이터',
            name: '/datasetMix'
        },
        {
            display: '통합 데이터 검색',
            name: '/integratedDataset'
        }
    ];

    useEffect(() => {
        if(paramsId !== match.params.id || paramsId === match.params.id){
            let params = {
                id: match.params.id
            };
            DataApi.readData(params).then(res => {
                setDataInfo(res.result);
            }).catch(res => {
                setShow404(true);
            });

            DataApi.readDownloadCount(params).then( res => {
                setDownloadCount(res.result.result);
            }).catch( res=> {
                setDownloadCount(0);
            })

            paramsId = match.params.id;
        }
    }, [match.params.id]);

    return (
        <>
            <div className="renewal">
            <SubNavNew nav={subNav} history={props.history} />
            </div>            
            {dataInfo !== null ? <ItemInfo key={dataInfo.id} dataInfo={dataInfo} downloadCount={downloadCount} {...props}/> : <div><Indicator /></div>}
            {show404 && <ErrorPage404/>}
        </>
    )

};

export default Item;

const ItemInfo = ({dataInfo, downloadCount, ...props}) => {
    const dispatch = useDispatch();

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    let [relationship, setRelationship] = useState([]);
    let [linkedFrom, setLinkedFrom] = useState([]);
    let [targetId, setTargetId] = useState(dataInfo.id);

    let [purpose, setPurpose] = useState('');
    let [plan, setPlan] = useState('');
    let [isCheckPurpose, setCheckPurpose] = useState(false);
    let [isCheckPlan, setCheckPlan] = useState(false);
    let [isShowOrder, setIsShowOrder] = useState(false);
    let [isShowReport, setIsShowReport] = useState(false);
    let [isShowShare, setIsShowShare] = useState(false);

    let [spinner1, setSpinner1] = useState(false);
    let [spinner2, setSpinner2] = useState(false);
    let [spinner3, setSpinner3] = useState(false);

    let [isSubmit, setIsSubmit] = useState(false);

    const downloadFrame = useRef(null);
    const paymentFrame = useRef(null);

    const [policyAgree, setPolicyAgree] = useState(true);
    const [readPolicy, setReadPolicy] = useState(false);

    const [paymentTypeFlag, setPaymentTypeFlag] = useState(false);
    const [paymentType, setPaymentType] = useState("");

    const [selectCategoryDemand, setSelectCategoryDemand] = useState(userInfo.user_type);
    const categoryDemand = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryDemand)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    // 이용신청 카테고리
    const [selectCategoryPurpose, setSelectCategoryPurpose] = useState(false);
    const [selectCategoryEtc, setSelectCategoryEtc] = useState(null);
    const [inputCategoryEtc, setInputCategoryEtc] = useState("");

    const categoryPurpose = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryPurpose)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    // 데이터 오류 신고 카테고리
    const [selectCategoryReportType, setSelectCategoryReportType] = useState(false);
    const [selectCategoryReportEtc, setSelectCategoryReportEtc] = useState(null);
    const [inputCategoryReportEtc, setInputCategoryReportEtc] = useState("");

    const categoryReportType = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryReportType)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    const [contractAgree, setContractAgree] = useState(false);
    const [isContractShow, setIsContractShow] = useState(false);

    useEffect(() => {
        const params = {
            id: targetId
        };
        DataApi.readRelationship(params).then(res => {
            let relation_data = res.result.relation_data.map(item => item.result);
            let linked_from = res.result.linked_from.map(item => item.result);

            setLinkedFrom(linked_from);
            setRelationship(relation_data);
        });
    }, [targetId]);

    const addFavoriteData = (user_id, data_info) => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
            // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
            //     confirmButtonText:"로그인",
            //     cancelButtonText:"확인",
            //     showCancelButton: true
            // }).then((result) => {
            //     if(result.value) {
            //         delCookie("token");
            //         window.location.href = "/#/login";
            //     }
        // });
        else{
            const params = {
                user_id_key: user_id,
                package_id: data_info.id,
                package_name: data_info.title
            };

            FavoriteDataApi.addFavoriteData(params)
                .then(res => {
                    swal('관심데이터 등록이 성공하였습니다.')
                })
                .catch(res => {
                    let rst = JSON.parse(res.response);
                    if(rst.message === "session_expire"){

                    }else swal('관심데이터가 이미 등록이 되어있습니다.');
                })
        }
    };


    let Catalog_Catalog = [];
    let DataService_DataService = [];
    let DataSet_DataSet = [];
    let DataSet_Distribution = [];
    let Ikan_Extra = [];

    let dataExtras = dataInfo.extras;

    let metaInfoWithRedux = useSelector(state => state.data.metaInfo);

    let metaInfo = JSON.parse(JSON.stringify(metaInfoWithRedux));

    dataExtras.map(meta => {
        // dataInfo.extras.map(meta => {
        let keyArr = meta.key.split("_");
        let target;
        if(keyArr[0]+"_"+keyArr[1] === "Catalog_Catalog") target = Catalog_Catalog;
        else if(keyArr[0]+"_"+keyArr[1] === "DataService_DataService") target = DataService_DataService;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_DataSet") target = DataSet_DataSet;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_Distribution") target = DataSet_Distribution;
        else if(keyArr[0]+"_"+keyArr[1] === "Ikan_Extra") target = Ikan_Extra;

        let keyText = meta.key.replace(keyArr[0]+"_"+keyArr[1]+"_", "");

        if(target !== undefined)
            target.push({
                key: keyText,
                display: meta.display,
                value: meta.value
            });
    });

    const searchInfoToWhat=(info,what)=>{
        let date="";
        info.map(i=> {
                if (i.key === what) {
                    date=i.value.replaceAll("-",".")
                    date=date.replace("년",".")
                    date=date.replace("월",".")
                    date=date.replace("일","")
                }
            }
        )
        return date
    }

    Object.keys(metaInfo).map((key, index) => {

        let target;
        if(key === "Catalog_Catalog") target = Catalog_Catalog;
        else if(key === "DataService_DataService") target = DataService_DataService;
        else if(key === "DataSet_DataSet") target = DataSet_DataSet;
        else if(key === "DataSet_Distribution") target = DataSet_Distribution;
        else if(key === "Ikan_Extra") target = Ikan_Extra;

        if(target !== undefined)
            metaInfo[key].map((meta, idx) => {
                target.some(t => {
                    if(t.key === meta.key)
                        metaInfo[key][idx] = {
                            ...metaInfo[key][idx],
                            value: t.value
                        };

                    return t.key === meta.key;
                });

            });
    });

    metaInfo.view = [];

    let meta_ikan_extra = JSON.parse(JSON.stringify(metaInfo.Ikan_Extra));

    // 가격문의 메타 설정
    let Ikan_Extra_priceInquiry = undefined;
    meta_ikan_extra.some(row => {
        if(row.key === "priceInquiry") {
            Ikan_Extra_priceInquiry = row.value.toLowerCase();
        }
    });

    // let metas = ["creator_name", "accrualPeriodicity", "issued", "modified", "license", "language", "version", "ds_price", "keyword"];
    // 200717 수정
    // custom_modfied = ckan에서 제공하는 최초등록일 및 갱신일자
    let metas = ["creator_name", "accrualPeriodicity", "issued", "custom_modified", "license", "language", "version", "priceInfo", "keyword", "contactPoint_phone"];
    let dataInfo_metadata_created = dataInfo.metadata_created;
    let dataInfo_metadata_modified = dataInfo.metadata_modified||dataInfo.metadata_created;
    // searchInfoToWhat(dataInfo.extras,"DataSet_DataSet_modified")
    console.log(dataInfo_metadata_modified);
    metas.map(key => {
        let meta_dataset = JSON.parse(JSON.stringify(metaInfo.DataSet_DataSet));
        let meta_dataservice = JSON.parse(JSON.stringify(metaInfo.DataService_DataService));

        if (key === "custom_modified") {
            const custom_metadata = {
                "display": "갱신일자" + "<br/><small>(최초등록일)</small>",
                "key": "DataSet_DataSet_modified",
                "value": yyyymmdd(new Date(
                    // (dataInfo_metadata_modified)?dataInfo_metadata_modified:dataInfo_metadata_created
                    dataInfo_metadata_modified
                )) + "<br/> <small>" + yyyymmdd(new Date(dataInfo_metadata_created)) + "</small>"
            };
            metaInfo.view.push(custom_metadata)
        }

        meta_dataservice.some(row => {
            if (key === row.key) {
                // priceInfo가 공급가로 바뀜 따로 원가 저장하는 메타데이터 필드 생성 됨
                if(row.key === "priceInfo") {
                    /*
                        Ikan_Extra_priceInquiry
                        true 가격문의
                        false 가격있음
                    */
                    if (Ikan_Extra_priceInquiry === "true") {
                        row.value = "가격문의";
                    } else if (!Ikan_Extra_priceInquiry || Ikan_Extra_priceInquiry === "false" ) {
                        if (Number.isInteger(Number(row.value)) && row.value < 0) {
                            // row.value = addComma(row.value) + "원";
                            row.value = "가격문의";
                        }
                        else if (Number.isInteger(Number(row.value)) && row.value > 0) {
                            row.value = addComma(row.value) + "원 <small>(VAT 포함)</small>";
                        }
                        else if (!row.value || Number(row.value) === 0) {
                            row.value = "무료";
                        }
                    }
                    metaInfo.view.push(row)
                }
            }
        });

        meta_dataset.some(row => {
            if (row.key === "priceInfo") {
                return ;
            } else {
                if(key === row.key){
                    if(row.value === undefined)
                        row.value = "";

                    // let check_col = metaInfo.DataService_DataService.filter((v) => (v.key === 'priceInfo'));
                    // let price_tmp = check_col[0];
                    // if(row.key === "ds_price"){
                    //     if (Number.isInteger(Number(price_tmp.value)) && price_tmp.value < 0) {
                    //         // row.value = addComma(row.value) + "원";
                    //         price_tmp.value = "가격문의";
                    //     }
                    //     else if (!price_tmp.value || Number(price_tmp.value) === 0) {
                    //         price_tmp.value = "무료";
                    //     }
                    //     row.value = price_tmp.value;
                    // }

                    if(row.key === "language")
                        row.value = "한국어"; // 2019-12-17 JSJ - 한국어 고정

                    if(row.value === "")
                        row.value = "-";

                    metaInfo.view.push(row);
                }
            }
            return key === row.key;
        });
    });
    // console.log(metaInfo)
    //파일 다운로드(+연타방지)
    let [downloadFlag, setDownloadFlag] = useState(false);
    const fileDownload = (resourceId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner2(true);
        if (!downloadFlag) {
        const params = {
            resource_id: resourceId,
            user_id: userInfo.id_key,
            package_name: dataInfo.title,
            user_name: userInfo.id,
            data_id:dataInfo.id
        };

        setDownloadFlag(true);
        DataApi.downloadData(params).then(res => {

                let download_url = process.env.REACT_APP_ICAN_API_URL + res.result.download_url;
                let varExt = res.result.file_name.split('.');
                if (varExt[varExt.length - 1] === "txt" || varExt[varExt.length - 1] === "pdf") {
                    window.open(download_url);
                } else {
                    downloadFrame.current.src = download_url;
                }
                setSpinner2(false);
                setDownloadFlag(false)
            }).catch(res => {
                setSpinner2(false);
                setDownloadFlag(false)
            });
            // DataApi.downloadPreviewData(params).then(res => {
            //     downloadFrame.current.src = res.result;
            //     setSpinner2(false);
            // }).catch(res => {
            //     setSpinner2(false);
            // });
        }
    };
    const sampleFileDownload = (sampleId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner1(true);
        const params = {
            sample_id: sampleId,
        };
        DataApi.downloadPreviewData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner1(false);
        }).catch(res => {
            swal('등록된 샘플파일이 없습니다.')
            setSpinner1(false);
        });
    };

    const schemaFileDownload = (schemaId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner3(true);
        let params = {
            schema_id: schemaId,
        };
        DataApi.downloadSchemaInfoData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner3(false);
        }).catch(res => {
            swal('등록된 스키마 정보 파일이 없습니다.')
            setSpinner3(false);
        });
    };

    const handleOrderRequest = () => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
        //     confirmButtonText:"로그인",
        //     cancelButtonText:"확인",
        //     showCancelButton: true
        // }).then((result) => {
        //     if(result.value) {
        //         delCookie("token");
        //         window.location.href = "/#/login";
        //     }
        // });

        else {
            setIsShowOrder(true);
        }
    };
    const handleDataReport = () => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        else{
            setIsShowReport(true)
        }
    }
    const checkEmptyValue = (target, setValue, value) => {
        if (value.length !== 0) {
            target(true);
            setValue(value);
        }
        else {
            target(false);
        }
    };

    const isPassValidate = () => {
        let validateArr = [isCheckPurpose, isCheckPlan, policyAgree];

        return validateArr.indexOf(false) < 0;
    };

    const priceCheck = (dataInfo) => {
        let price = 0;
        dataInfo.extras.map(row => {
            //임시 수정
            if(row.key === "DataService_DataService_priceInfo"){
                price = Number(row.value);
            }
        });

        return price;
    }

    const checkNull = (value) => {
        if (value === null || value === undefined || value === "" || value === " ") {
            return "";
        } else {
            return value;
        }
    };

    const isPayment = () => {
        if(selectCategoryPurpose === false){
            swal("활용목적을 선택해주세요.");

            return
        }
        if (selectCategoryEtc === "기타") {
            if(inputCategoryEtc == null || inputCategoryEtc === "" || inputCategoryEtc === " ") {
                swal ("활용목적을 입력해주세요.")

                return
            }
        }

        let price = 0;
        price = priceCheck(dataInfo);

        if(price >= 1000){
            setIsContractShow(true);//계약서 보이게 하겠는가,
        }else{
            setContractAgree(true);
            isPayment2(dataInfo);
        }

    }

    //
    const isPayment2 = (dataInfo) => {

        let price = 0;
        price = priceCheck(dataInfo);

        if(price >= 1000){
            if(contractAgree === false){
                swal("이용약관에 동의하셔야 이용가능합니다.");
                return
            }
        isPayOrder(dataInfo);
    }


  if(true){//체크가 되고 눌렸는가
            let price = 0;
            price = priceCheck(dataInfo);

            if(Ikan_Extra_priceInquiry === "true" || price < 0){
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "Inquiry",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand,
                    etc_desc: inputCategoryEtc
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('안녕하세요 경기지역경제포털 입니다.\n' +
                            '해당 데이터는 제공 기관 담당자에게 문의해주시기 바랍니다. \n' +
                            '감사합니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                        setInputCategoryEtc(null);
                        setSelectCategoryEtc(null);
                        setSelectCategoryPurpose(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                        setInputCategoryEtc(null);
                        setSelectCategoryEtc(null);
                        setSelectCategoryPurpose(false);
                    });
            }
            else if(price >= 1000){
                setPaymentTypeFlag(true);
            }else{
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "FREE",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand,
                    etc_desc: inputCategoryEtc
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('이용신청이 완료되었습니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                        setInputCategoryEtc(null);
                        setSelectCategoryEtc(null);
                        setSelectCategoryPurpose(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                        setInputCategoryEtc(null);
                        setSelectCategoryEtc(null);
                        setSelectCategoryPurpose(false);
                    });
            }
        }
    };

    // 활용목적 false 수정필요
    const isPayOrder = (dataInfo) => {
        setPaymentTypeFlag(false);
        setPaymentType("CARD");
        
        let userAgent = navigator.userAgent.toLowerCase();

        var params = {
            "itemname": dataInfo.title,
            "itemid": dataInfo.id,
            "username": userInfo.id,
            "useremail": userInfo.email,
            "userphone": userInfo.phone,
            "useridkey": userInfo.id_key,
            "purpose": selectCategoryPurpose,
            "plan": selectCategoryDemand,
            "etc_desc": inputCategoryEtc,
            "url": window.location.href
        };
        
        var form = document.createElement("form");
        form.method = "POST";
        form.target = "paymentFrame";
        form.action = process.env.REACT_APP_PAYMENT_API_URL;

        // 모바일 구분
        // if (userAgent.indexOf("android") > -1 || userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
        //     form.action = process.env.REACT_APP_PAYMENT_MOBILE_API_URL;
        // } else {
        //     form.action = process.env.REACT_APP_PAYMENT_API_URL;
        // }

        for(let key in params){
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
        form.parentNode.removeChild(form);

        setIsSubmit(true);

        let receiveMessage = (event) => {
            let response = event.data;
            if(response.type === "cancel"){
                swal("취소되었습니다.");
                setIsSubmit(false);
                setIsShowOrder(false);
            }else if(response.type === "error"){
                swal(response.returnMsg + "\n\n문제가 계속될 경우 고객센터로 문의 바랍니다.");
                setIsSubmit(false);
                setIsShowOrder(false);
            }else if(response.type === "success"){
                swal('이용신청이 완료되었습니다.');
                setIsShowOrder(false);
                setIsSubmit(false);
            }else if(response.type === "결제오류"){
                swal(response.msg);
                setIsShowOrder(false);
                setIsSubmit(false);                
            }
        };
        window.addEventListener("message", receiveMessage, false);
    }

    let paymentFrameStyle = {};
    let paymentFrameClass = "";
    if(isSubmit){
        paymentFrameClass = " ds-go";
        if (paymentType === "CARD") {
            paymentFrameStyle = {};
        } else {
            paymentFrameStyle = {
                "width": "720px",
                "height": "620px",
            }
        }

    }else{
        paymentFrameStyle = {
            position: "fixed",
            left: "-99999px",
            width: "1px",
            height: "1px"
        };
        paymentFrameClass = "";
    }

    //임시
    const isReadPolicy = () => {
        setReadPolicy(true);
        terms("orderPolicy");
    };

    const isCancelPopup = () => {
        setIsShowOrder(false)
        setIsShowReport(false);
        setIsShowShare(false);
        setReadPolicy(false)
        setInputCategoryEtc(null);
        setSelectCategoryEtc(null);
        setSelectCategoryPurpose(false);
        setSelectCategoryReportType(false);
        setSelectCategoryReportEtc(null);
        setPaymentTypeFlag(false);
        setIsContractShow(false);
    };

    const dataReportRequest = (dataInfo) => {
        if(selectCategoryReportType === false){
            swal("신고유형을 선택해주세요.");
            return
        }
        if(inputCategoryReportEtc == null || inputCategoryReportEtc === "" || inputCategoryReportEtc === " ") {
            swal ("신고내용을 입력해주세요.")
            return
        }

        if(true) {
            const package_center_code = dataInfo.extras.filter(ext => {return ext.key === "Ikan_Extra_centerCode"})[0].value;
            const creator_mail = dataInfo.extras.filter(ext => {return ext.key === "DataSet_DataSet_creator_mail"})[0].value;

            let params = {
                package_id: dataInfo.id,
                package_name: dataInfo.title,
                creator_mail: creator_mail,
                center_code: package_center_code,
                user_id: userInfo.id,
                user_name: userInfo.fullname,
                user_email: userInfo.email,
                report_type: selectCategoryReportType,
                user_memo: inputCategoryReportEtc,
            };

            DataApi.reportData(params)
                .then(res => {
                    swal('데이터 오류신고가 완료되었습니다.');
                    setIsShowReport(false);
                })
                .catch(res => {
                    setIsShowReport(false);
                });
        }
        setInputCategoryReportEtc(null);
        setSelectCategoryReportEtc(null);
        setSelectCategoryReportType(false);
    }

    // 투어
    const dataDetailTour = new TourMessage.DataSetDetailPageTourMessage()
    const isTourOpen = useSelector(state => state.user.isTourOpen)
    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }

    const replace2many=()=>{
        let string="";
        string=contractPolicy.replace(/\n/g, '<br/>');
        string=string.replace('+dataPrice+' , priceCheck(dataInfo));
        string=string.replace('+dataName+' , checkNull(dataInfo.title));
        string=string.replace('+dataUser_address+' , checkNull(userInfo.address+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+userInfo.detail_address));
        string=string.replace('+dataUser_company+' , checkNull(userInfo.user_desc));
        string=string.replace('+dataUser_representor+' , checkNull(userInfo.fullname));
        string=string.replace('+dataUser_phone+' , checkNull(userInfo.phone));
        return string;
    }

    return (
        <>
            <div className={"ds_payment-wrap" + paymentFrameClass}>
                <iframe ref={paymentFrame} title={"paymentFrame"} name={"paymentFrame"} style={paymentFrameStyle}/>
            </div>
            <iframe ref={downloadFrame} title={"downloadFrame"} name={"downloadFrame"} width={1} height={1} style={{
                position: "fixed",
                left: "-99999px"
            }}/>
            <Tour
                steps={dataDetailTour.tourConfig}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
            <div className={"wrap-dataset-view"}>
                <div className="title-view" data-tut={"dataset_subject"}>
                    <h1>
                        {dataInfo.title}

                        <div className={"dataset-share"} onClick={() => setIsShowShare(true)}>
                            <div style={{"display": "inline-block", "position": "relative", "top": "10px"}}>
                                <img src={shareImg} width={"32px"} height={"32px"} alt={"share"}/>
                            </div>
                            <div style={{"display": "inline-block", "marginLeft": "5px"}}>
                                <span>공유하기</span>
                            </div>
                        </div>

                        <div className={"dataset-share"} onClick={() => addFavoriteData(userInfo.id_key, dataInfo)}>
                            <div style={{"display": "inline-block", "position": "relative", "top": "10px", "marginLeft": "12px"}}>
                                <img src={heartImg} width={"32px"} height={"32px"} alt={"heart"}/>
                            </div>
                            <div style={{"display": "inline-block", "marginLeft": "5px"}}>
                                <span>찜하기</span>
                            </div>
                        </div>

                        <div className={"dataset-share"} onClick={() => handleDataReport()}>
                            <div style={{"display": "inline-block", "position": "relative", "top": "10px", "marginLeft": "12px"}}>
                                <img src={sirenImg} width={"32px"} height={"32px"} alt={"siren"}/>
                            </div>
                            <div style={{"display": "inline-block", "marginLeft": "5px"}}>
                                <span>오류신고</span>
                            </div>
                        </div>

                    </h1>


                    <p dangerouslySetInnerHTML={{__html:dataInfo.notes.replace(/\r\n|\r|\n/g, "<br />").replace(/□ /g,"")}} />
                    {/*<div style={{"width":"auto", "margin":"0 auto", "overflow": "hidden"}}>*/}
                    {/*    <div style={{"float":"left", "width":"auto"}}>*/}
                    {/*        <h1>{dataInfo.title}</h1>*/}
                    {/*    </div>*/}
                    {/*    <div style={{"cursor":"pointer"}} onClick={() => setIsShowShare(true)}>*/}
                    {/*        <div style={{"float":"left", "width":"auto", "margin-left": "20px"}}>*/}
                    {/*            <img style={{'margin': "25px 0px"}} src={shareImg} width={"35px"} height={"35px"}/>*/}
                    {/*        </div>*/}
                    {/*        <div style={{"float":"left", "width":"auto","margin-left": "10px"}}>*/}
                    {/*            <div style={{'margin': "30px 0px"}}>공유하기</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/* 키워드 바로 검색 */}
                <div className ="keyword-search" style={{"padding-bottom":"25px"}}>
                    <span className="keyword-tag">	    
                        {dataInfo.tags.map((tag, index) => (
                            <a 
                                href={`#/new-dataset?word=${tag.name}`}
                                style={{ 
                                    display: 'inline-block', 
                                    marginRight: '10px', 
                                    marginBottom: '10px',
                                    padding: '0 15px', 
                                    border: '1px solid #a6afb2', 
                                    borderRadius: '20px', 
                                    // color: '#a1a1a', 
                                    fontWeight: 500, 
                                    lineHeight: '29px', 
                                    backgroundColor: '#fff',
                                    //backgroundColor : 'rgba(0, 120, 255, 10%)',
                                    textDecoration: 'none',                                      
                                }}
                            >

                                #{tag.name}
                            </a>
                        ))}
                    </span>
                </div>
                <div className={"dataset-info"}>
                    <div className={"dataset-meta"} data-tut={"dataset_meta"}>
                        <MetaInfoView initialMetaInfo={metaInfo.view} key={JSON.stringify(metaInfo.view)}/>
                    </div>
                    <div className={"dataset-data"} data-tut={"dataset_info"}>
                        {dataInfo.resources.map(resource => <DatasetResource key={resource.id}
                                                                             resource={resource}
                                                                             sampleInfo={dataInfo.sample_info}
                                                                             schemaInfo={dataInfo.schema_info}
                                                                             fileDownload={fileDownload}
                                                                             sampleFileDownload={sampleFileDownload}
                                                                             schemaFileDownload={schemaFileDownload}
                                                                             sampleDownloadLoading={spinner1}
                                                                             downloadLoading={spinner2}
                                                                             schemaDownloadLoading={spinner3}
                                                                             dataInfo={dataInfo}
                                                                             handleOrderRequest={handleOrderRequest}
                                                                             addFavoriteData={addFavoriteData}
                                                                             handleDataReport={handleDataReport}
                                                                             downloadCount={downloadCount}
                        />)}
                    </div>
                    {/*<div className={"btn-group"}>*/}
                    {/*    /!*추가*!/*/}
                    {/*    /!*<Link to={"/order/" + dataInfo.id}>*!/*/}
                    {/*    /!*    <button>*!/*/}
                    {/*    /!*        주문*!/*/}
                    {/*    /!*    </button>*!/*/}
                    {/*    /!*</Link>*!/*/}
                    {/*    <button onClick={() => handleOrderRequest()}>*/}
                    {/*        이용신청하기*/}
                    {/*    </button>*/}
                    {/*    <button onClick={() => addFavoriteData(userInfo.id_key, dataInfo)}> 찜하기</button>*/}
                    {/*    <button onClick={() => handleDataReport()} className={"data-report-button"}>오류신고</button>*/}
                    {/*</div>*/}

                                
                    <div className="relation-data">
                        <h3>연관 데이터</h3>
                        <ul className={""}>
                            {relationship.map(row => {
                                return (<li key={JSON.stringify(row)}>
                                    <Link to={"/dataset/" + row.id}>
                                        <span>{row.title}</span>
                                    </Link>
                                    <span> 은(는) </span>
                                    <Link to={"/dataset/" + dataInfo.id}>
                                        <span>{dataInfo.title}</span>
                                    </Link>
                                    <span> 와(과) </span>
                                    <span>연관이 있습니다.</span>
                                </li>)
                            })}

                            {linkedFrom.map(row => {
                                return (<li key={JSON.stringify(row)}>
                                    <Link to={"/dataset/" + dataInfo.id}>
                                        <span>{dataInfo.title}</span>
                                    </Link>
                                    <span> 은(는) </span>
                                    <Link to={"/dataset/" + row.id}>
                                        <span>{row.title}</span>
                                    </Link>
                                    <span> 와(과) </span>
                                    <span>연관이 있습니다.</span>
                                </li>)
                            })}
                        </ul>
                    </div>
                    <div className="dataset-comment" style={{'marginTop': '30px'}}>
                        <h4>댓글</h4>
                        <DatasetComment postId={dataInfo.id} postTheme={"dataset"} postTitle={dataInfo.title} postPlaceholder={"데이터에 대한 댓글을 남겨주세요."}/>
                    </div>
                </div>
            </div>

            {isShowOrder && <div className="terms">
                <div className={"wrap-order-pop"}>
                    <div className={"wrap-form"}>
                        {/*<h1>수요기업(필수)</h1>*/}
                        {/*<div className={"orderSelectWrap first"}>*/}
                        {/*    <SelectBox options={categoryDemand}*/}
                        {/*               onChange={option => {*/}
                        {/*                   setSelectCategoryDemand(option.name);*/}
                        {/*               }}*/}
                        {/*               value={selectCategoryDemand}*/}
                        {/*               placeholder={"수요 기업 선택"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<textarea key={"A"} placeholder="구입목적을 입력해주세요." onChange={e => checkEmptyValue(setCheckPurpose, setPurpose, e.target.value)}/>*/}
                        <h1>활용목적(필수)</h1>
                        {/*<textarea key={"B"} placeholder="이용계획을 입력해주세요." onChange={e => checkEmptyValue(setCheckPlan, setPlan, e.target.value)}/>*/}
                        <div className={"orderSelectWrap second"}>
                            <SelectBox options={categoryPurpose}
                                       onChange={option => {
                                           setSelectCategoryPurpose(option.name);
                                           setSelectCategoryEtc(option.display);
                                       }}
                                       value={selectCategoryPurpose}
                                       placeholder={"활용 목적 선택"}
                            />
                        </div>
                    </div>
                    {
                        (selectCategoryEtc === "기타") ?
                            <input type={"text"} placeholder={"활용목적을 입력해주세요."}
                                   className={"order-etc-input"}
                                   title={"활용목적"}
                                   onChange={e => setInputCategoryEtc(e.target.value)}
                            />
                            : null
                    }
                    <div className="btn-group ac" style={{"position":"absolute", "bottom": "10px", "left": "0px", "right": "0px"}}>
                        <button onClick={()=>isPayment()}> 이용신청하기 </button>
                        <button onClick={()=>isCancelPopup()}> 취소 </button>
                    </div>
                    {
                        (isContractShow===true)?
                            <div className={"add"}>
                                <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{ __html: replace2many() }} />
                                <CheckBox label={
                                    <>
                                        <div style={{ "display": "inline-block", "color": "red" }}>
                                            (필수)
                                        </div>
                                        <div style={{ "display": "inline-block", "marginLeft": "3px" }}>
                                            이용약관에 동의합니다.
                                        </div>
                                    </>
                                }
                                          id="contractAgree" checked={contractAgree} onChange={ (bool)=>{setContractAgree(bool);} }
                                />
                                <div className="btn-group ac" style={{"position":"absolute", "bottom": "10px", "left": "0px", "right": "0px"}}>
                                    <button onClick={()=>isPayment2(dataInfo)}> 계속하기 </button>
                                    <button onClick={()=>isCancelPopup()}> 취소 </button>
                                </div>
                            </div>
                            :null
                    }
                </div>
            </div>}
            {isShowReport && <div className="terms">
                <div className={"wrap-order-pop"}>
                    <div className={"wrap-form"}>
                        <h1>신고 유형(필수)</h1>
                        <div className={"orderSelectWrap second"}>
                            <SelectBox options={categoryReportType}
                                       onChange={option => {
                                           setSelectCategoryReportType(option.name);
                                           setSelectCategoryReportEtc(option.display);
                                       }}
                                       value={selectCategoryReportType}
                                       placeholder={"신고 유형 선택"}
                            />
                        </div>
                    </div>

                    <input type={"text"} placeholder={"신고내용 입력해주세요."}
                           className={"order-etc-input"}
                           title={"신고내용"}
                           onChange={e => setInputCategoryReportEtc(e.target.value)}
                    />

                    <div className="btn-group ac" style={{"position":"absolute", "bottom": "10px", "left": "0px", "right": "0px"}}>
                        <button onClick={()=>dataReportRequest(dataInfo)}> 신고하기 </button>
                        <button onClick={()=>isCancelPopup()}> 취소 </button>
                    </div>
                </div>
            </div>}
            {isShowShare && <div className="terms">
                <div style={{"height":"auto"}} className={"wrap-order-pop"}>
                    <div className={"wrap-form"}>
                        <div style={{"cursor":"pointer","position":"absolute" ,"right":"16px", "fontWeight":"bold", "fontSize":"18px"}} onClick={()=>isCancelPopup()}>X</div>
                        <h1>데이터셋 공유하기</h1>
                        <ShareSNS dataInfo={dataInfo}/>
                    </div>

                </div>
            </div>}
            {/* 이니시스에서는 따로 구분 X
             {paymentTypeFlag && <div className="terms">
                <div style={{"height":"auto"}} className={"wrap-order-pop"}>
                    <div className={"wrap-form"}>
                        <div style={{"cursor":"pointer","position":"absolute" ,"right":"16px", "font-weight":"bold", "font-size":"18px"}} onClick={()=>isCancelPopup()}>X</div>
                        <h1>결제 방법 선택</h1>
                        <div className="btn-group ac" style={{"margin-top": "15px"}}>
                            <button onClick={()=>isPayOrder(dataInfo, "CARD")}> 카드결제 </button>
                            <button onClick={()=>isPayOrder(dataInfo, "MONEY")}> 계좌이체 </button>
                        </div>
                    </div>
                </div>
            </div>} 
            */}
        </>
    )
};
