import React from 'react';

const TableState = ({tableState, rowTotal, page, maxPage}) => {

    if(tableState){
        return (
            <div className={"datatable-state"}>
                {page}/{maxPage}
                {rowTotal>0 && <span>&nbsp;전체&nbsp;:&nbsp;</span>}{rowTotal>0 && rowTotal}
            </div>
        )   
    }
    else return null;
};

TableState.defaultProps = {
    page: 1,
    maxPage: 1,
    tableState: false, 
    rowTotal: -1, 
};

export default TableState;
