import React, {useState,useEffect, useRef} from 'react';
import CheckBox from "./CheckBox";
import swal from "sweetalert";
import {DaumPostcode} from "react-daum-postcode";
import SelectBox from "./SelectBox";
import {useSelector} from "react-redux";

import * as UserApi from "../api/User";
import {index as privacyPolicy} from "../util/Terms/privacyPolicy";
import {index as termsAndConditions} from "../util/Terms/termsAndConditions";
import {index as privacyCollectionAndAgreePolicy} from "../util/Terms/privacyCollectionAndAgreePolicy"
import {getParamsFromQueryString, serialize} from "../util/Url";


const Login = (props) => {

    const [joinStep, setJoinStep] = useState(false);
    const [joinSecondStep, setJoinSecondStep] = useState(false);
    const [agree, setAgree] = useState(false);
    const [agreeB, setAgreeB] = useState(false);
    const [agreeC, setAgreeC] = useState(false);
    const [agreeD1, setAgreeD1] = useState(false);
    const [agreeD2, setAgreeD2] = useState(false);
    const [agreeD3, setAgreeD3] = useState(false);
    const [agreeD4, setAgreeD4] = useState(false);
    const [agreeD5, setAgreeD5] = useState(false);
    const [agreeD6, setAgreeD6] = useState(false);
    const [agreeD7, setAgreeD7] = useState(false);
    const [agreeD8, setAgreeD8] = useState(false);
    const [agreeD9, setAgreeD9] = useState(false);
    const [agreeM, setAgreeM] = useState(false);
    const [agreeD, setAgreeD] = useState(0);
    const [agreeZ, setAgreeZ] = useState(false);
    const [agreeALL, setAgreeALL] = useState(false);
    const [id, setId] = useState("");
    const [isDupId, setIsDupId] = useState(false); // 개발 시 기본값 true
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSamePassword, setIsSamePassword] = useState(true);
    const [email, setEmail] = useState("");
    const [isDupEmail, setIsDupEmail] = useState(false); // 개발 시 기본값 true
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [processLoading, setProcessLoading] = useState(false);
    const [emailAgree, setEmailAgree] = useState(false);
    const [detailAddress, setDetailAddress] = useState("");
    const [postCode, setPostCode] = useState("")
    const [isCheckPhoneAuth, setIsCheckPhoneAuth] = useState(false);
    const [isCheckId, setCheckId] = useState(false);
    const [isCheckEmail, setCheckEmail] = useState(false);
    const [isCheckName, setCheckName] = useState(false);
    const [isCheckAddress, setCheckAddress] = useState(false);
    const [isCheckPhone, setCheckPhone] = useState(false);
    const [isCheckDetailAddress, setCheckDetailAddress] = useState(false);
    const [isCheckPostCode, setCheckPostCode] = useState(false);
    const [isShowPostCode, setIsShowPostCode] = useState(false);
    const [isCheckOnlyCompanySortation, setCheckOnlyCompanySortation] = useState(false);
    const [isCheckOnlyCompanyType, setCheckOnlyCompanyType] = useState(false);
    const [isCheckUserDesc, setCheckUserDesc] = useState(false);
    const [selectBoxValue, setSelectBoxValue] = useState(false);
    const [userTypeDescFlag, setUserTypeDescFlag] = useState(null);
    const [userDesc, setUserDesc] = useState(null);
    const [onlyCompanySortation, setOnlyCompanySortation] = useState(null);
    const [onlyCompanyType, setOnlyCompanyType] = useState(null);
    const [isCheckIdDuplicate, setCheckIdDuplicate] = useState(true);
    const categoryDemand = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categorySortation)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    const companyCategory = [
        {
            display: '대기업',
            name: '대기업'
        },
        {
            display: '중견기업',
            name: '중견기업',
        },
        {
            display: '중소기업',
            name: '중소기업'
        },
        {
            display: '소기업',
            name: '소기업'
        },
        {
            display: '소상공인',
            name: '소상공인'
        },
        {
            display: '스타트업',
            name: '스타트업'
        }
    ];

    useEffect(() => {
        let set_params = {
            "step": "1"
        }
        props.history.replace({
            pathname: window.location.search,
            search: serialize(set_params)
        });

    }, [])

    useEffect( () => {
        let qs = getParamsFromQueryString(props.history.location.search);

        let params_step = qs['step'][0];

        if (params_step === "1") {
            setJoinStep(false);
        } else if (params_step === "2") {
            // 2번째 스텝을 간 건 모두 동의한 것
            setAgree(true);
            // 201019 개인정보점검 개인정보처리방침 동의 삭제
            //setAgreeB(true);

            setAgreeC(true);
            setAgreeZ(true);
            setAgreeALL(true);

            setJoinStep(true);
            setJoinSecondStep(false);
        } else if (params_step === "3") {
            setJoinStep(true);
            setJoinSecondStep(true);
        }
    }, [props.history.location.search])

    const changePassword = (target, value) => {
        if(target === "password"){
            setPassword(value);

            if(passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        }else if(target === "passwordConfirm"){
            setPasswordConfirm(value);

            if(password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };
    const isFirstStepValidate = () => {
        let validateArr = [agree,
                          // agreeB, 231019 개인정보점검 개인정보처리방침 검증에서 제거
                          agreeC, 
                          agreeZ,
                          agreeD1,
                          agreeD3,
                          agreeD5];

        return validateArr.indexOf(false) < 0;
    };

    const isSecondStepValidate = () => {
        let validateArr = [isCheckId, isSamePassword, isCheckEmail, isCheckName, emailAgree, isCheckPhone, isCheckPhoneAuth];

        return validateArr.indexOf(false) < 0;
    };

    const isPassValidate = () => {

        let validateArr = [isSamePassword, isCheckId, isCheckEmail, isCheckName, isCheckAddress, isCheckPhone, agree, agreeC, agreeZ,agreeD1,agreeD3,agreeD5,
            isCheckDetailAddress, isCheckPostCode, emailAgree, selectBoxValue, isCheckUserDesc, isCheckOnlyCompanySortation, isCheckOnlyCompanyType];

        return validateArr.indexOf(false) < 0;
    };

    const checkEmptyValue = (target, setValue, value) => {
        if(value.length !== 0){
            target(true);
            setValue(value);
        }else{
            setValue("");
            target(false);
        }
    };

    const isSetPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if(str.length < 4){
            setPhone(str)
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            setPhone(tmp)
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            setPhone(tmp)
        }else{
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            setPhone(tmp)
        }
        if(str.length >= 10 && str.length<=11){
            setCheckPhone(true);
        }else{
            setCheckPhone(false);
        }
    };
    const checkboxStyle = {
        width: "80px",
        marginTop: "10px"
    }
    const checkboxStyle2 = {
        marginTop: "10px"
    }    
    const joinProcess = () => {
        setProcessLoading(true);

        let regExpId = /^[0-9a-z\-_]+$/;
        if(id && !regExpId.test(id)){
            swal("ID는 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.").then(res => {
                refIdInput.current.focus()
            });
            setProcessLoading(false);
            return;
        }

        if (password?.length < 8 || !(password.search(/[A-Z]/) >= 0 && password.search(/[a-z]/) >= 0 && password.search(/\d/) >= 0 && password.search(/[#?!@$%^&*-]/) >= 0)) {
            swal("비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다.").then( res => {
                refPwInput.current.focus()
            })
            setProcessLoading(false);
            return;
        }

        if(isPassValidate()){
            let joinInfo = {
                id: id,
                password: password,
                name: name,
                email: email,
                emailAgree: emailAgree,
                address: address,
                phone: phone,
                detail_address: detailAddress,
                postcode: postCode,
                user_type: selectBoxValue,
                termsAndConditions: agree,
                // 231019 개인정보점검 개인정보처리방침 삭제
                //privacyPolicy: agreeB,
                privacyAgreePolicy: agreeC,
                privacyOption: agreeD,
                user_desc: userDesc,
                only_company_sortation: onlyCompanySortation,
                only_company_type: onlyCompanyType
            };
            props.joinProcess(joinInfo, () => {
                setProcessLoading(false)
            });
        }else{
            swal("모두 입력해주세요.");
            setProcessLoading(false);
        }
    };

    const toJoinSecondStep = () => {
        let set_params = {
            "step": "2"
        }

        //D2,4,6번 숫자로 계산
        let choose=0;
        if(agreeD2) choose+=1
        if(agreeD4) choose+=2
        if(agreeD6) choose+=4
        if(agreeD7) choose+=8
        if(agreeD8) choose+=16
        if(agreeD9) choose+=32
        setAgreeD(choose)

        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });
        setJoinStep(true);
    };

    const toJoinThirdStep = () => {
        let set_params = {
            "step": "3"
        }
        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });
        setJoinSecondStep(true);
    }

    const toPreviousFirstStep = () => {
        let set_params = {
            "step": "1"
        }
        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });

        setJoinStep(false);
    }

    const toPreviousSecondStep = () => {
        let set_params = {
            "step": "2"
        }
        props.history.replace({
            pathname: window.location.search,
            search: serialize(set_params)
        });
        setJoinSecondStep(false);

    }

    const handleFindPostCode = () => {
        setIsShowPostCode(true);
    };

    const handleAddress = (data) => {
        checkEmptyValue(setCheckAddress, setAddress, data.address);
        checkEmptyValue(setCheckPostCode, setPostCode, data.postcode === "" ? data.zonecode : data.postcode);
        setIsShowPostCode(false);
    };

    const handleAgreeChanged = (bool, type) => {
        if (type === "ALL") {
            setAgree(bool);
            // 231019 개인정보점검 개인정보처리방침 삭제
            // setAgreeB(bool);
            
            setAgreeC(bool);
            setAgreeD1(bool);setAgreeD3(bool);setAgreeD4(bool);setAgreeD5(bool);
            setAgreeD7(bool);setAgreeD8(bool);setAgreeD9(bool);setAgreeM(bool);
            setAgreeZ(bool);
            setAgreeALL(bool);

            //231019 개인정보점검에서 선택항목을 수집하지 않으므로 동의내용에서 제거
            // setAgreeD2(bool);
            // setAgreeD6(bool);
        }
        else if (type === "A") {
            setAgree(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        // 231019 개인정보점검 개인정보처리방침 삭제
        // else if (type === "B") {
        //     setAgreeB(bool);
        //     if (agreeALL) {
        //         setAgreeALL(false)
        //     }
        // }
        else if (type === "C") {
            setAgreeC(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "D1") {
            setAgreeD1(bool); // 회원가입 및 관리 필수
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        //231019 개인정보점검에서 선택항목을 수집하지 않으므로 동의내용에서 삭제
        // else if (type === "D2") {
        //     setAgreeD2(bool); // 회원가입 및 관리 선택
        //     if (agreeALL) {
        //         setAgreeALL(false)
        //     }
        // }
        else if (type === "D3") {
            setAgreeD3(bool); // 서비스 계약 및 거래 필수
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "D4") {
            setAgreeD4(bool); // 영업 및 마케팅 선택
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "D5") {
            setAgreeD5(bool); // 민원 처리 필수
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        //231019 개인정보점검에서 선택항목을 수집하지 않으므로 동의내용에서 제거        
        // else if (type === "D6") {
        //     setAgreeD6(bool); // 민원처리 선택
        //     if (agreeALL) {
        //         setAgreeALL(false)
        //     }
        // }
        else if (type === "D7") {
            setAgreeD7(bool); // SMS 
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "D8") {
            setAgreeD8(bool); // E-Mail
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "D9") {
            setAgreeD9(bool); // 휴대전화
            if (agreeALL) {
                setAgreeALL(false)
            }                                    
        }else if (type === "Z") {
            setAgreeZ(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }else if (type === "M") {
            setAgreeD7(bool); // SMS 
            setAgreeD8(bool); // E-Mail
            setAgreeD9(bool); // 휴대전화
            setAgreeM(bool); // 마켓팅 선택사항
        }
    };

    const onIdBlur = (t) => {
        let regExpId = /^[0-9a-z\-_]+$/;
        let params = {
            'user_id': id
        };
        if(id && !regExpId.test(id) ){
            swal("ID는 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.").then(res => {
                setId("")
                refIdInput.current.focus();
            });
        } else {
            UserApi.checkDuplicateId(params).then( res => {
                if (res.result === "use") {
                    swal("이미 사용중인 ID 입니다.").then(res => {
                        setId("")
                        refIdInput.current.focus();
                    });
                }
            });
        }

        let regExpPw = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (password && !regExpPw.test(password)) {
            swal("비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다.").then( res => {
                setPassword("")
                setPasswordConfirm("")
                refPwInput.current.focus();
            });
        }
        return true;
    };

    const initUserType = () => {
        setCheckUserDesc(false);
        setCheckOnlyCompanyType(false);
        setCheckOnlyCompanySortation(false);
        setUserDesc(null);
        setOnlyCompanySortation(null);
        setOnlyCompanyType(null);
        setCheckOnlyCompanyType(null);
    };

    const selectUserType = (user_type) => {
        setSelectBoxValue(user_type.name);
        setUserTypeDescFlag(user_type.display);

        initUserType();

        if (user_type.display === "개인") {
            setUserDesc("개인");
            setCheckUserDesc(true);
            setCheckOnlyCompanyType(true);
            setCheckOnlyCompanySortation(true);
        } else if (user_type.display === "기타" || user_type.display === "학교" || user_type.display === "공공기관" || user_type.display === "연구소") {
            setCheckOnlyCompanyType(true);
            setCheckOnlyCompanySortation(true);
        }
    };

    const openPhoneIdentification = () => {
        var params = {
            // "mid": process.env.REACT_APP_PHONE_AUTH_MID,
            // "mTxId": process.env.REACT_APP_PHONE_AUTH_MTXID,
            // "reqSvcCd": process.env.REACT_APP_PHONE_AUTH_REQSVCCD,
            // "reservedMsg": "isUseToken=Y",
            // "authHash": process.env.REACT_APP_PHONE_AUTH_authHash,
            // "flgFixedUser": "N",
            // "successUrl": process.env.REACT_APP_PHONE_AUTH_URL_SUCCESS,
            // "failUrl": process.env.REACT_APP_PHONE_AUTH_URL_SUCCESS          
        };

        var form = document.createElement("form");
        form.method = "POST";
        form.target = "sa_popup";
        // form.action = "https://sa.inicis.com/auth";
        form.action = process.env.REACT_APP_PHONE_AUTH_URL;

        for(let key in params){
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);

		const _width = 400;
		const _height = 620;
		var xPos = (document.body.offsetWidth/2) - (_width/2); // 가운데 정렬
		xPos += window.screenLeft; // 듀얼 모니터일 때

		window.open("", "sa_popup", "width="+_width+", height="+_height+", left="+xPos+", menubar=yes, status=yes, titlebar=yes, resizable=yes");
        form.submit();
        form.parentNode.removeChild(form);

        window.onmessage = function(e){
            let confirm_type = e.data.type;
            // console.log("onMessage");
            // console.log(e.data);
            // console.log(confirm_type);
            if(confirm_type === "success") {
                swal("본인인증이 완료되었습니다.");
                setIsCheckPhoneAuth(true);
                return;
            }else{
                swal("본인인증에 실패하였습니다.");
                return;
            }
        };
    }

    const refIdInput = useRef(null);
    const refPwInput = useRef(null);

    //이용약관 테이블
    const policy=()=>{
        let privacyPolicyString= privacyPolicy.replace(/\n/g, '<br/>');

        /**
         * 2022 보안감사 개인정보 수집시 고지하여야할 4가지 고지사항 안내
         */         
        privacyPolicyString = privacyPolicyString.replace(`INFORMPRIVACY_TABLE`,
        `<table style="width:100%;">
            <colgroup>
                <col width="25%"/>
                <col width="50%"/>
                <col width="25%"/>
            </colgroup>            
            <tr style="text-align:center;">
                <th>위탁업체</th>
                <th>위탁업무</th>
                <th>위탁기간</th>
            </tr>
            <tr>
                <td>
                    경기경제과학진흥원<br/>
                    선도소프트<br/>
                    한국평가데이터<br/>
                    씨씨미디어서비스
                </td>
                <td style="vertical-align:middle;">경기지역경제포털 플랫폼 구축 및 데이터 협업체계 강화</td>
                <td style="vertical-align:middle;">2023.05.30.~계약 종료 시</td>
            </tr>
        </table>`)    
        return privacyPolicyString
    }
    const CollectionPolicy=()=>{
        let privacyPolicyString=  privacyCollectionAndAgreePolicy.replace(/\n/g, '<br/>')        
        return privacyPolicyString.replace(`privacyCollectionAndAgreePolicyTable`,
            '<table > ' +
            '<th>제공받는 자</th><th>제공 목적</th><th>제공하는 항목</th><th>보유 및 이용 기간</th>' +
            '<tr><td>디몽</td><td>마케팅</td><td>이름, 이메일, 전화번호</td><td>서비스 해지 후 파기</td></tr>' +
            '<tr><td>한국신용데이터</td><td>마케팅</td><td>이름, 이메일, 전화번호</td><td>서비스 해지 후 파기</td></tr>' +
            '</table>')
    }

    const marketingPolicy=()=>{
        let marketingPolicyString = `
        경기지역경제포털은 개인정보보호법에 따라 본인의 동의를 받아 이용자 현황분석을 위한 개인정보 수집ㆍ이용 합니다. <br/>
        1. 이용항목 : 성명, 아이디 휴대전화번호, 이메일주소 <br/>
        2. 이용목적 : <span style="font-size:1.2em;font-weight:bolder;text-decoration:underline;color:black;">회원에게만 제공되는 정보 및 이벤트 소개</span> <br/>
        3. 보유 및 이용기간 : <span style="font-size:1.2em;font-weight:bolder;text-decoration:underline;color:black;">회원탈퇴 시 개인정보 일괄 삭제</span> <br/>
        `
        return marketingPolicyString
    }
    let checkText = isPassValidate() ? null : <span className={"all-check-text"}>&#8251; 모든 입력은 필수 입력 사항입니다.</span>;
    if (joinStep === false) {
        return (
            <>
                <div>
                    <div className={"join-form-step"}>
                        <div className={"join-form-step-wrap"}>
                            <p className={"join-form-step-head"}>STEP 01</p>
                            <p className={"join-form-step-body"}>약관 동의</p>
                        </div>
                        <div className={"join-form-step-wrap join-form-step-gray"}>
                            <p className={"join-form-step-head"}>STEP 02</p>
                            <p className={"join-form-step-body"}>기본 정보</p>
                        </div>
                        <div className={"join-form-step-wrap join-form-step-gray"}>
                            <p className={"join-form-step-head"}>STEP 03</p>
                            <p className={"join-form-step-body"}>추가 정보</p>
                        </div>
                    </div>
                    <ul className={"join-form"}>
                        <li style={{"paddingTop": "15px", "paddingBottom": "15px"}}>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'ALL')} checked={agreeALL} id={"agreeALL"}
                                      label={
                                          <>
                                              <div style={{"fontSize": "15px", "color": "#000", "fontWeight": "500"}}>
                                                  약관에 모두 동의합니다.
                                              </div>
                                          </>
                                      }/>
                        </li>
                        <li>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'Z')} checked={agreeZ} id={"agreeZ"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                  만 14세 이상입니다. ( 만 14세 미만은 이용 불가 )
                                              </div>
                                          </>
                                      }/>
                        </li>
                        <li>

                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: termsAndConditions.replace(/\n/g, '<br/>')}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'A')} checked={agree} id={"agree"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                  이용약관에 동의합니다.
                                              </div>
                                              {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                              {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                                          </>
                                      }/>
                        </li>
{/* 
                        231019 개인정보점검 개인정보 처리방침 동의 삭제
                        <li>
                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: policy()}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'B')} checked={agreeB} id={"agreeB"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "marginleft": "3px"}}>
                                                  개인정보처리방침에 동의합니다.
                                              </div>
                                              //<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;
                                              //</ul><i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의
                                          </>
                                      }/>
                        </li>
*/}
                        <li>
                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: CollectionPolicy()}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'C')} checked={agreeC} id={"agreeC"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                  개인정보 수집 및 이용에 동의합니다.
                                              </div>
                                              {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                              {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                                          </>
                                      }/>
                        </li>
                    </ul>

                    <strong>필수항목 동의</strong>
                    <table style={{width:"100%",fontSize:"10pt"}}>
                        <colgroup>
                            <col width="15%" />
                            <col width="30%" />
                            <col width="5%" />
                            <col width="30%" />
                            <col width="15%" />
                            <col width="5%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>수집항목</th>
                                <th>필수여부</th>
                                <th>수집 및 이용목적</th>
                                <th>보존기간</th>
                                <th>동의</th>
                            </tr>
                        </thead>    
                        <tbody>
                            <tr>
                                <td>회원가입 및 관리</td>
                                <td>성명, 아이디, 비밀번호, 휴대전화번호, 주소, 이메일주소</td>
                                <td style={{"color": "red", textAlign:"center", verticalAlign:"middle"}}>필수</td>
                                <td>본인 확인 및 가입의사 확인, 서비스 이용에 따른 본인 확인</td>
                                <td rowSpan="3" style={{textAlign:"center", verticalAlign:"middle",fontSize:"1.2em",fontWeight:"bolder",textDecoration:"underline"}}>회원탈퇴시 <br/><span>(개인정보 처리방침)</span></td>
                                <td style={{textAlign:"center", verticalAlign:"middle"}}>
                                    <CheckBox style={{ width:"100%", height:"100%", marginLeft:"7px" }} onChange={bool => handleAgreeChanged(bool, 'D1')} checked={agreeD1} id={"agreeD1"}/>
                                </td>
                            </tr>
                            <tr>
                                <td>서비스 계약 및 거래</td>
                                <td>성명, 아이디, 휴대전화번호, 이메일주소, 신용카드번호, 은행계좌정보 등 결제 정보</td>
                                <td style={{"color": "red", textAlign:"center", verticalAlign:"middle"}}>필수</td>
                                <td>서비스 이용에 따른 본인식별 및 상품 구매에 따른 대금 결제</td>
                                <td style={{textAlign:"center", verticalAlign:"middle"}}>
                                    <CheckBox onChange={bool => handleAgreeChanged(bool, 'D3')} checked={agreeD3} id={"agreeD3"} style={{ marginLeft:"7px" }}/>
                                </td>
                            </tr>
                            <tr>
                                <td>민원 처리</td>
                                <td>성명, 아이디, 이메일주소</td>
                                <td style={{"color": "red", textAlign:"center", verticalAlign:"middle"}}>필수</td>
                                <td>민원인의 신원 및 민원사항 확인, 사실조사를 위한 연락 등</td>
                                <td style={{textAlign:"center", verticalAlign:"middle"}}>
                                    <CheckBox onChange={bool => handleAgreeChanged(bool, 'D5')} checked={agreeD5} id={"agreeD5"} style={{ marginLeft:"7px" }}/>
                                </td>
                            </tr>   
                        </tbody>                  
                    </table>
                    <span>* 필수항목에 대한 동의를 거부할 권리가 있으나, 동의거부에 따른 서비스 이용에 제한이 있을 수 있습니다.</span><br/>
                    <div style={{marginTop:"15px"}}></div>
                    {/* <span>* 동의하지 않은 경우에도 경기지역경제포털 서비스는 이용하실 수 있습니다.</span> */}
{/* 
                    231019 개인정보점검 선택항목 동의 삭제
                    <strong>선택항목 동의</strong>
                    <table style={{width:"100%",fontSize:"10pt", marginBottom:"15px"}}>
                        <colgroup>
                            <col width = "15%" />
                            <col width = "30%" />
                            <col width = "5%" />
                            <col width = "30%" />
                            <col width = "15%" />
                            <col width = "5%" />
                        </colgroup>      
                        <thead> 
                            <tr>
                                <th>구분</th>
                                <th>수집항목</th>
                                <th>필수여부</th>
                                <th>수집 및 이용목적</th>
                                <th>보존기간</th>
                                <th>동의</th>                        
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>회원가입 및 관리</td>
                                <td>소속기관/부서명, 직장 전화번호</td>
                                <td style={{"color": "red", textAlign:"center", verticalAlign:"middle"}}>선택</td>
                                <td>본인 확인 및 가입의사 확인, 서비스 이용에 따른 본인 확인</td>
                                <td rowSpan={"3"} style={{textAlign:"center", verticalAlign:"middle"}}>회원탈퇴 시 <br/><span>(개인정보 처리방침)</span></td>
                                <td style={{textAlign:"center", verticalAlign:"middle"}}><CheckBox onChange={bool => handleAgreeChanged(bool, 'D2')} checked={agreeD2} id={"agreeD2"}/></td>
                            </tr>                                                

                            <tr>
                                <td>민원 처리</td>
                                <td>휴대전화번호</td>
                                <td style={{"color": "red", textAlign:"center", verticalAlign:"middle"}}>선택</td>
                                <td>민원인의 신원 및 민원사항 확인, 사실조사를 위한 연락 등</td>
                                <td style={{textAlign:"center", verticalAlign:"middle"}}><CheckBox onChange={bool => handleAgreeChanged(bool, 'D6')} checked={agreeD6} id={"agreeD6"}/></td>
                            </tr>
                        </tbody>
                    </table>
*/}
                    <strong>영업 및 마케팅 동의</strong>
                    <div style={{border:"1px solid #000", fontSize:"13.333px", padding:"5px 10px"}}>
                        <div className={"marketingPolicyWrap"} dangerouslySetInnerHTML={{__html: marketingPolicy()}} />
                        <div>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'M')} checked={agreeM} id={"agreeM"}
                                        label={
                                            <>
                                                <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                    광고/정보 수신 및 마케팅 활용에 전체 동의합니다.
                                                </div>
                                                <div style={{"display": "inline-block", "color": "red"}}>
                                                    (선택)
                                                </div>
                                            </>
                                } style={checkboxStyle2}/>                            
                        </div>
                        <div className={""} style={{display:"flex", width:"100%"}}>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'D7')} checked={agreeD7} id={"agreeD7"}
                                      label={
                                            <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                SMS
                                            </div>
                            } style={checkboxStyle}/>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'D8')} checked={agreeD8} id={"agreeD8"}
                                      label={
                                            <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                E-mail
                                            </div>
                            } style={checkboxStyle}/>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'D9')} checked={agreeD9} id={"agreeD9"}
                                      label={
                                            <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                                휴대전화
                                            </div>
                            } style={checkboxStyle}/>                                                                                
                        </div>
                    </div>
                    <span>* 선택 사항에 대한 동의를 거부할 권리가 있으나, 동의거부에 따른 서비스 이용에 제한이 있을 수 있습니다.</span><br/>
                    <span>* 동의하지 않은 경우에도 경기지역경제포털 서비스는 이용하실 수 있습니다.</span>

                    <button className={"button"} onClick={() => toJoinSecondStep()} style={{"marginBottom": "20px", "marginTop":"5px"}}
                            disabled={!isFirstStepValidate()}>동의</button>
                </div>
            </>
        )
    }
    else if(joinStep === true && joinSecondStep === false) {
        return (
            <>
                <div className={"join-form-step"}>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 01</p>
                        <p className={"join-form-step-body"}>약관 동의</p>
                    </div>
                    <div className={"join-form-step-wrap"}>
                        <p className={"join-form-step-head"}>STEP 02</p>
                        <p className={"join-form-step-body"}>기본 정보</p>
                    </div>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 03</p>
                        <p className={"join-form-step-body"}>추가 정보</p>
                    </div>
                </div>

                <div>
                    <ul className={"join-form"}>
                        <li>
                            <input type={"text"} placeholder={"아이디"} title={"ID"} value={id}
                                   ref={refIdInput}
                                //    autoComplete='off'
                                   onChange={e => {
                                       checkEmptyValue(setCheckId, setId, e.target.value, id);
                                   }}
                            />
                            <p className={"join-warning-text"}>&#8251; 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.</p><br></br>
                        </li>
                        <li>
                            <input type={"password"} placeholder={"비밀번호"} title={"PASSWORD"} value={password}
                                   ref={refPwInput}
                                //    autoComplete='off'
                                   onChange={e => changePassword("password", e.target.value)}
                                   onClick={e => onIdBlur(refPwInput.current)}
                                   onFocus={e => onIdBlur(refPwInput.current)}
                            />
                            <p className={"join-warning-text"}>&#8251; 비밀번호는 8자 이상이어야 하며<br/>영문대문자/영문소문자/숫자/특수문자를 모두 포함해야 합니다.</p><br></br>
                        </li>
                        <li>
                            <input type={"password"} placeholder={"비밀번호 확인"} title={"PASSWORD CONFIRM"}
                                   value={passwordConfirm}
                                //    autoComplete='off'
                                   onChange={e => changePassword("passwordConfirm", e.target.value)}
                                   onClick={e => onIdBlur(refPwInput.current)}
                                   onFocus={e => onIdBlur(refPwInput.current)}
                            />
                            <span className={"txt-wrong"}>{!isSamePassword && "비밀번호가 일치하지 않습니다."}</span>
                        </li>
                        <li>
                            <input type={"text"} placeholder={"이메일"} title={"EMAIL"} value={email}
                                   onChange={e => checkEmptyValue(setCheckEmail, setEmail, e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                            <p className={"join-warning-text"} style={{color: "#426d9e", opacity: 1}}>&#8251; 회원 가입 인증메일 및
                                정보메일 스팸 필터링 방지를 위해 사내메일이 아닌 상용메일(gmail, naver, daum 등)을 이용해 회원가입해주세요.</p>
                        </li>
                        <li>
                            <CheckBox label={
                                <>
                                    <div style={{"display": "inline-block", "color": "red"}}>
                                        (필수)
                                    </div>
                                    <div style={{"display": "inline-block", "marginLeft": "3px"}}>
                                        이메일 수신 동의
                                    </div>
                                </>
                            }
                                      id={"emailAgree"} checked={emailAgree} onChange={(bool) => {
                                setEmailAgree(bool);
                            }
                            }/>
                        </li>
                        <li>
                            <input type={"text"} placeholder={"이름"} title={"NAME"} value={name}
                                   onChange={e => checkEmptyValue(setCheckName, setName, e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <input type={"text"} placeholder={"전화번호"} value={phone} title={"전화번호"}
                                   onChange={e => isSetPhoneNumber(e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                                   style={{width: "65%", minWidth: "250px", display: "inline-block"}}
                            />
                            
                            <button className={"button"} onClick={() => openPhoneIdentification()}
                                        style={{"width": "35%", "display": "inline-block", "minWidth": "80px", "marginTop": "0px"}}> 인증 </button>
                            
                        </li>
                    </ul>
                    {checkText}
                    <div style={{"marginbottom": "20px"}}>
                        <button className={"button"} onClick={() => toPreviousFirstStep()} style={{"display": "inline-block", "width": "48%", "marginRight": "10px"}}> 이전 </button>
                        <button className={"button"} onClick={() => toJoinThirdStep()} style={{"display": "inline-block", "width": "48%"}}
                                disabled={!isSecondStepValidate()}> 다음 </button>
                    </div>
                </div>
            </>
        )
    }
    else if (joinStep === true && joinSecondStep === true) {
        return(
            <>
                <div className={"join-form-step"}>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 01</p>
                        <p className={"join-form-step-body"}>약관 동의</p>
                    </div>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 02</p>
                        <p className={"join-form-step-body"}>기본 정보</p>
                    </div>
                    <div className={"join-form-step-wrap"}>
                        <p className={"join-form-step-head"}>STEP 03</p>
                        <p className={"join-form-step-body"}>추가 정보</p>
                    </div>
                </div>
                {isShowPostCode && <div className={"ds-2-daum-post-code-wrap"}>
                    <DaumPostcode
                        onComplete={data => handleAddress(data)}
                    />
                    <button className={"ds-2-daum-post-code-close-btn"}
                            onClick={() => setIsShowPostCode(false)}
                    >
                        닫기
                    </button>
                </div>}
                <div>
                <ul className={"join-form"}>
                    <li>
                        <div className={"joinFormSelectBoxWrap"}>
                            <SelectBox options={categoryDemand}
                                       onChange={option => {
                                           // setSelectBoxValue(option.name);
                                           selectUserType(option);
                                       }}
                                       value={selectBoxValue}
                                       placeholder={"회원 유형 선택"}
                            />
                        </div>
                    </li>
                    {
                        (userTypeDescFlag === "기업") ?
                            <li>
                                <SelectBox options={companyCategory}
                                           onChange={option => {
                                               setOnlyCompanySortation(option.name);
                                               setCheckOnlyCompanySortation(true);
                                           }}
                                           value={onlyCompanySortation}
                                           placeholder={"기업구분"}
                                />
                                <input type={"text"} placeholder={"기업 분야 예) IT, 제조, 금융, 유통 등"} title={"기업분야"}
                                       value={onlyCompanyType}
                                       onChange={e => checkEmptyValue(setCheckOnlyCompanyType, setOnlyCompanyType, e.target.value)}
                                       onClick={e => onIdBlur(refIdInput.current)}
                                       onFocus={e => onIdBlur(refIdInput.current)}
                                />
                                <input type={"text"} placeholder={"기업명"} title={"기업명"}
                                       value={userDesc}
                                       onChange={e => checkEmptyValue(setCheckUserDesc, setUserDesc, e.target.value)}
                                       onClick={e => onIdBlur(refIdInput.current)}
                                       onFocus={e => onIdBlur(refIdInput.current)}
                                />
                            </li> :
                            null
                    }
                    {
                    (userTypeDescFlag === "기타") ?
                        <li>
                            <input type={"text"} placeholder={"유형을 입력해주세요."} title={"유형을 입력해주세요."}
                                   value={userDesc}
                                   onChange={e => checkEmptyValue(setCheckUserDesc, setUserDesc, e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li> :
                        null

                    }
                    {
                        // || userTypeDescFlag === "공공기관" || userTypeDescFlag === "연구소"
                        (userTypeDescFlag === "학교") ?
                            <li>
                                <input type={"text"} placeholder={"학교의 명칭을 입력해주세요."} title={"소속기관의 이름을 입력해주세요."}
                                       value={userDesc}
                                       onChange={e => checkEmptyValue(setCheckUserDesc, setUserDesc, e.target.value)}
                                       onClick={e => onIdBlur(refIdInput.current)}
                                       onFocus={e => onIdBlur(refIdInput.current)}
                                />
                            </li> :
                            null

                    }
                    {
                        (userTypeDescFlag === "공공기관") ?
                            <li>
                                <input type={"text"} placeholder={"기관의 명칭을 입력해주세요."} title={"소속기관의 이름을 입력해주세요."}
                                       value={userDesc}
                                       onChange={e => checkEmptyValue(setCheckUserDesc, setUserDesc, e.target.value)}
                                       onClick={e => onIdBlur(refIdInput.current)}
                                       onFocus={e => onIdBlur(refIdInput.current)}
                                />
                            </li> :
                            null

                    }
                    {
                        (userTypeDescFlag === "연구소") ?
                            <li>
                                <input type={"text"} placeholder={"연구소의 명칭을 입력해주세요."} title={"소속기관의 이름을 입력해주세요."}
                                       value={userDesc}
                                       onChange={e => checkEmptyValue(setCheckUserDesc, setUserDesc, e.target.value)}
                                       onClick={e => onIdBlur(refIdInput.current)}
                                       onFocus={e => onIdBlur(refIdInput.current)}
                                />
                            </li> :
                            null

                    }
                    <li>
                        <button className={"ds-2-find-address"} onClick={() => handleFindPostCode()}>
                            주소찾기
                        </button>
                        <input type={"text"} placeholder={"우편번호"} title={"우편번호"}
                               className={"ds-2-find-address-input"}
                               value={postCode}
                               disabled={true}
                               onClick={e => onIdBlur(refIdInput.current)}
                               onFocus={e => onIdBlur(refIdInput.current)}
                        />
                    </li>
                    <li>
                        <input type={"text"} placeholder={"주소"} title={"주소"}
                               value={address}
                               disabled={true}
                               onClick={e => onIdBlur(refIdInput.current)}
                               onFocus={e => onIdBlur(refIdInput.current)}
                        />
                    </li>
                    <li>
                        <input type={"text"} placeholder={"상세주소"} title={"상세주소"}
                               onChange={e => checkEmptyValue(setCheckDetailAddress, setDetailAddress, e.target.value)}
                               value={detailAddress}
                               onClick={e => onIdBlur(refIdInput.current)}
                               onFocus={e => onIdBlur(refIdInput.current)}
                        />
                    </li>
                </ul>
                </div>
                {checkText}
                <div style={{"marginbottom": "20px"}}>
                    <button className={"button"} onClick={() => toPreviousSecondStep()} style={{"display": "inline-block", "width": "48%", "marginRight": "10px"}}> 이전 </button>

                    <button className={"button"} onClick={() => joinProcess()} style={{"display": "inline-block", "width": "48%"}}
                            disabled={!isPassValidate()}>{(processLoading ?
                        "가입중" : "가입")}</button>
                </div>
            </>
        )
    }
};

Login.propTypes = {
    joinProcess: () => {
    }
};

export default Login;
